import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const LandownersPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Landowners" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-secondary">
      <section>
        <p>Whether your land is purely an investment, a part of your family heritage, a piece of what once was or still is your farming operation, or even a little bit of each, it is an attractive asset and we congratulate you on your position as a landowner.</p>
        <p>Farming is a competitive business and landowners are our most important customers. No matter the market cycle, today landowners deserve:</p>
        <ul>
          <li>To have regular and open communication with their tenant</li>
          <li>To be proud of their land when they see it</li>
          <li>To know that their farm’s productivity is being maintained and improved</li>
          <li>To be treated with respect and given the level of control over their land that they desire</li>
          <li>To work with a farm that cares for the tradition and heritage of their land</li>
          <li>To be educated on the inputs, the technology, and the people growing a crop on their land</li>
          <li>To count on rent payments being on time and in full</li>
          <li>To receive competitive rents, and a solid return on investment</li> 
        </ul>
        <p>If you are not getting all of this with your current arrangement, we would love the opportunity to offer the service you deserve. Please call our office at <a href="tel:17657597432">(765) 759-7432</a> or email <a href="mailto:adam@howellfarms.com?subject=Landowner Inquiry">adam@howellfarms.com</a>.</p>
      </section>
    </div>
    
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "landowners.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default LandownersPage
